import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chapters-paginator"
export default class extends Controller {
  static values = {
    mangaChapterUrl: String
  }

  changeChapterSelection(event) {
    const targetUri = this.mangaChapterUrlValue.replace(':chapter_id', event.target.value)
    Turbo.visit(targetUri)
  }
}
