import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="manga--chapters"
export default class extends Controller {
    static targets = ["branchSelector"]
    branchId = null


    connect() {
        console.log('connected', this.element)
    }

    branchSelectorTargetConnected() {
        this.branchId = this.branchSelectorTarget.value
        // console.log('branchSelectorTargetConnected', this.branchId)
        this.branchSelectorTarget.addEventListener('change', this.changeBranch.bind(this))
        this.branchSelectorTarget.dispatchEvent(new Event('change'));
    }

    changeBranch() {
        this.branchId = this.branchSelectorTarget.value
        console.log('changeBranch', this.branchId)
        console.log(`.js-branch_${this.branchId}`)
        this.element.querySelectorAll('.js-branch').forEach(el => el.classList.add('d-none'))
        this.element.querySelectorAll(`.js-branch_${this.branchId}`).forEach(el => el.classList.remove('d-none'))
    }
}
