import { BridgeComponent, BridgeElement } from "@hotwired/strada"

// Connects to data-controller="bridge--example"
export default class extends BridgeComponent {
  static component = 'example'
  static targets = ['firstButton', 'log']

  connect() {
    super.connect()
    console.log("Connected to example controller")
    this.send("connect", { hello: 'world' }, () => {
      console.log('callback from connect')
    })
  }

  firstButtonTargetConnected() {
    console.log('firstButtonTargetConnected')

    this.send('firstButtonTargetConnected', { }, () => {
      console.log('callback from firstButtonTargetConnected')
      this.logTarget.innerText = 'Connected to first button target'
    });

    this.firstButtonTarget.addEventListener('click', this.firstButtonTargetOnClick.bind(this))
  }

  firstButtonTargetOnClick(event) {
    this.send('firstButtonTargetClicked', { }, (response) => {
      console.log('callback from firstButtonTargetConnected')
      this.logTarget.innerText = `callback from firstButtonTargetConnected: response:${JSON.stringify(response)}`
    });
  }
}


