import { BridgeComponent } from "@hotwired/strada"

// Connects to data-controller="bridge--screen-direction"
export default class extends BridgeComponent {
  static component = 'screen-direction'
  static values = {
    direction: String
  }

  connect() {
    super.connect()

    this.send("connect", { direction: this.directionValue }, () => {
      console.log('callback from connect')
    })
  }
}
