import {Controller} from "@hotwired/stimulus"
import {post, destroy} from "@rails/request.js"

// Connects to data-controller="manga-card"
export default class extends Controller {
    static targets = ['addToFavoritesBtn', 'removeFromFavoritesBtn']
    static values = {
        id: Number,
        favorites: Boolean,
        addToFavoritesMangaUrl: String,
        removeFromFavoritesMangaUrl: String
    }

    favoritesValueChanged() {
        if (this.favoritesValue) {
            this.addToFavoritesBtnTarget.classList.add('d-none')
            this.removeFromFavoritesBtnTarget.classList.remove('d-none')
        } else {
            this.removeFromFavoritesBtnTarget.classList.add('d-none')
            this.addToFavoritesBtnTarget.classList.remove('d-none')
        }
    }

    addToFavorites(event) {
        post(this.addToFavoritesMangaUrlValue)
            .then(() => this.favoritesValue = true)
    }

    removeFromFavorites(event) {
        const options = {
            body: JSON.stringify({id: this.idValue}),
        }
        destroy(this.removeFromFavoritesMangaUrlValue, options)
            .then(() => this.favoritesValue = false)
    }
}
