import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="manga--chapter--page"
export default class extends Controller {
    static values = {
        id: Number,
        index: Number,
        biteSize: Number,
        imageUrl: String
    }

    static targets = ['image', 'progress', 'progressBar']

    connect() {
    }

    imageTargetConnected() {
        console.log('imageTargetConnected')
        this.downloadImage()
    }

    setProgress(percent) {
        this.progressBarTarget.style.width = `${percent}%`
        this.progressBarTarget.setAttribute('aria-valuenow', percent)
    }

    hideProgress() {
        this.progressTarget.style.display = 'none'
    }

    async downloadImage() {
        try {
            const response = await fetch(this.imageUrlValue, {cache: 'force-cache'});
            const reader = response.body.getReader();
            let receivedLength = 0;
            const chunks = [];

            while (true) {
                const {done, value} = await reader.read();
                if (done) break;
                chunks.push(value);
                receivedLength += value.length;
                this.setProgress(Math.round(receivedLength * 100 / this.biteSizeValue));
            }

            const blob = new Blob(chunks, {type: 'image/webp'});
            const fileReaderPromise = new Promise(resolve => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(blob);
            });

            this.hideProgress();
            this.imageTarget.src = await fileReaderPromise;
        } catch (error) {
            setTimeout(this.downloadImage.bind(this), 1000);
        }
    }
}
