import {Controller} from "@hotwired/stimulus"
import {post} from "@rails/request.js"

// Connects to data-controller="manga--chapter"
export default class extends Controller {
    markedAsReaded = false;
    prevScrollPercent = 0;

    static values = {
        markAsReadedUrl: String,
    }

    connect() {
        this.bindedHandleWindowScroll = this.handleWindowScroll.bind(this)

        document.addEventListener("scroll", this.bindedHandleWindowScroll);
    }

    disconnect() {
        super.disconnect();
        document.removeEventListener("scroll", this.bindedHandleWindowScroll);
    }

    handleWindowScroll(event) {
        let scrollTop = window.scrollY;
        let docHeight = document.body.offsetHeight;
        let winHeight = window.innerHeight;
        let scrollPercentRounded = Math.round((scrollTop / (docHeight - winHeight)) * 100);

        if (this.prevScrollPercent === scrollPercentRounded) {
            return;
        }

        this.prevScrollPercent = scrollPercentRounded;

        if (this.prevScrollPercent >= 60 && this.markedAsReaded === false) {
            this.markedAsReaded = true;

            post(
                this.markAsReadedUrlValue,
                {
                    contentType: 'application/json',
                    body: JSON.stringify({
                        load_next: true
                    }),
                    responseKind: "json"
                }
            ).then()
        }
    }
}
